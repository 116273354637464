<template>
  <div class="b-card-content">
    <b-form>
      <div class="form-pool-choice px-5 pt-3 pb-4">
        <b-row align-v="center" align-h="between" no-gutters>
          <b-col>
            <TokenInput
                :title="'Input A'"
                direction="from"
                :token-amount="toBigNumber($store.state.pools.from.amount)"
                :token-name="$store.state.pools.from.symbol"
                :tooltip="'The number of tokens you will add to the pool'"
                :chooseTokenDisabled="id !== 'ADD_NEW_LIQUIDITY'"
                :isEstimated="form.estimated === 'from'"
                @estimateAmount="estimateAmount"
                @chooseToken="chooseToken"
            >
            </TokenInput>
          </b-col>
          <b-col md="auto" class="pt-4 pool-choice-icon" clickable>
            <svg width="18" height="18" viewBox="0 0 18 18" style="margin: auto 8px" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.666687 9.00001C0.666687 4.39751 4.39752 0.666672 9.00002 0.666672C13.6025 0.666672 17.3334 4.39751 17.3334 9.00001C17.3334 13.6025 13.6025 17.3333 9.00002 17.3333C4.39752 17.3333 0.666687 13.6025 0.666687 9.00001ZM9.00002 2.33334C7.23191 2.33334 5.53622 3.03572 4.28598 4.28596C3.03573 5.5362 2.33335 7.2319 2.33335 9.00001C2.33335 10.7681 3.03573 12.4638 4.28598 13.7141C5.53622 14.9643 7.23191 15.6667 9.00002 15.6667C10.7681 15.6667 12.4638 14.9643 13.7141 13.7141C14.9643 12.4638 15.6667 10.7681 15.6667 9.00001C15.6667 7.2319 14.9643 5.5362 13.7141 4.28596C12.4638 3.03572 10.7681 2.33334 9.00002 2.33334Z"
                    fill="#B5B5C3"/>
              <path
                  d="M8.16671 4.83334V8.16668H4.83337V9.83334H8.16671V13.1667H9.83337V9.83334H13.1667V8.16668H9.83337V4.83334H8.16671Z"
                  fill="#B5B5C3"/>
            </svg>
          </b-col>
          <b-col>
            <TokenInput
                :title="'Input B'"
                direction="to"
                :token-amount="toBigNumber($store.state.pools.to.amount)"
                :token-name="$store.state.pools.to.symbol"
                :tooltip="'The number of tokens you will add to the pool'"
                :chooseTokenDisabled="id !== 'ADD_NEW_LIQUIDITY'"
                :isEstimated="form.estimated === 'to'"
                @estimateAmount="estimateAmount"
                @chooseToken="chooseToken"
            >
            </TokenInput>
          </b-col>
        </b-row>
      </div>
      <template v-if="this.isTokensHasName">
        <b-row class="px-5 pt-3">
          <b-col sm="12" md="6">
            <b-form-row>
              <b-col>
                <TextBlock label="Share of pool"
                           tooltip="How much of the total liquidity pool your tokens will take"
                           :amount="form.shareOfPool"
                           :append="'%'"
                           :amountTooltip="form.shareOfPoolTooltip"
                ></TextBlock>
              </b-col>
            </b-form-row>
            <b-form-row class="mt-3">
              <b-col>
                <TextBlock label="Pool tokens received"
                           tooltip="Number of pool tokens you will receive for your input"
                           :amount="form.poolTokensReceived"
                           :amountTooltip="form.poolTokensReceivedTooltip"
                ></TextBlock>
              </b-col>
            </b-form-row>
            <b-form-row class="mt-3" v-if="isTokenHasId">
              <b-col>
                <TextBlock label="Current pool size"
                           tooltip="Number of tokens in liquidity pool"
                           :amount="form.currentPoolSize"
                           :noParse="true"
                           :append="`${$store.state.pools.from.symbol}/${$store.state.pools.to.symbol}`"
                           :amountTooltip="form.currentPoolSizeTooltip"
                ></TextBlock>
              </b-col>
            </b-form-row>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-row>
              <b-col class="mt-3 mt-md-0">
                <TextBlock label="Ratio A/B"
                           tooltip="Ratio of tokens in liquidity pool"
                           :amount="form.ratioAB"
                           :append="`${$store.state.pools.from.symbol} per ${$store.state.pools.to.symbol}`"
                           :amountTooltip="form.ratioABTooltip"
                ></TextBlock>
              </b-col>
            </b-form-row>
            <b-form-row class="mt-3">
              <b-col>
                <TextBlock label="Ratio B/A"
                           tooltip="Ratio of tokens in liquidity pool"
                           :amount="form.ratioBA"
                           :append="`${$store.state.pools.to.symbol} per ${$store.state.pools.from.symbol}`"
                           :amountTooltip="form.ratioBATooltip"
                ></TextBlock>
              </b-col>
            </b-form-row>
          </b-col>
        </b-row>
        <b-row class="px-5" v-if="this.providedPool">
          <b-col sm="12" md="6" class="mt-3">
            <TextBlock label="Current share of pool"
                       tooltip="Percent of the total liquidity pool you own"
                       :amount="form.currentShareOfPool"
                       :amountTooltip="form.currentShareOfPoolTooltip"
                       :append="'%'"
            ></TextBlock>
          </b-col>
          <b-col sm="12" md="6" class="mt-3">
            <TextBlock label="Pool tokens owned"
                       tooltip="Amount of your pool tokens"
                       :amount="form.poolTokensOwned"
                       :amountTooltip="form.poolTokensOwnedTooltip"
            ></TextBlock>
          </b-col>
        </b-row>
        <b-row class="px-5 pt-3" v-if="!isTokenHasId">
          <b-col>
            <div>
              <div class="caption info">
                You are about to create a new liquidity pool
              </div>
              <div class="data">
                <span>Your ratio of tokens will set the price of this pool</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-form>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import {
  HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
  HTTP_GET_POOL_TOKENS_TOTAL,
  HTTP_GET_PROVIDED_POOLS,
  HTTP_GET_TOKEN_BALANCES_BY_ID,
  RESET_POOLS_STATE,
  SET_POOLS_TOKEN_DATA
} from "@/store/modules/pools.module";
import {mathRound} from "@/helpers/utils";
import BigNumber from "bignumber.js";

export default {
  name: "AddLiquidity",
  components: {
    TextBlock: () => import("@/components/form/TextBlock"),
    TokenInput: () => import("@/components/form/input/TokenInput"),
  },
  props: {
    id: {type: String},
    tokenFrom: {type: String, default: ''},
    tokenTo: {type: String, default: ''}
  },
  data: function () {
    return {
      form: {
        shareOfPool: null,
        shareOfPoolTooltip: null,
        poolTokensReceived: null,
        poolTokensReceivedTooltip: null,
        ratioAB: null,
        ratioABTooltip: null,
        ratioBA: null,
        ratioBATooltip: null,
        currentPoolSize: null,
        currentPoolSizeTooltip: null,
        currentShareOfPool: null,
        currentShareOfPoolTooltip: null,
        poolTokensOwned: null,
        poolTokensOwnedTooltip: null,
        estimated: null
      },
      providedPool: null
    }
  },
  watch: {
    '$store.state.pools': {
      handler: async function () {
        if (this.isTokensHasAmount) {
          this.calculateFormData()
        }
      },
      deep: true
    },
    '$route': {
      handler: async function (route) {
        let tFrom = null
        let tTo = null
        if (Object.keys(route.query).length > 0) {
          const baseToken = this.$store.state.tokens.baseToken

          tFrom = !this.checkBaseToken(route.query.f) ? this.$store.state.tokens.tokens[route.query.f] : baseToken
          tTo = !this.checkBaseToken(route.query.t) ? this.$store.state.tokens.tokens[route.query.t] : baseToken
          if (!tFrom || !tTo) {
            await this.$router.push('/liquidity')
            return
          }
          await this.chooseToken({direction: 'from', token: tFrom})
          await this.chooseToken({direction: 'to', token: tTo})
        }
        if (route.name === 'liquidity-pool') {
          await this.httpGetProvidedPools()
          if (!this.existsInProviderPools) {
            await this.$router.push(`/liquidity?f=${tFrom?.symbol}&t=${tTo?.symbol}`)
          }
        }
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions([
      RESET_POOLS_STATE,
      HTTP_GET_POOL_ID_BY_TOKEN_PAIR,
      HTTP_GET_TOKEN_BALANCES_BY_ID,
      HTTP_GET_POOL_TOKENS_TOTAL,
      HTTP_GET_PROVIDED_POOLS
    ]),
    ...mapMutations([SET_POOLS_TOKEN_DATA]),
    toBigNumber(val) {
      return new BigNumber(val)
    },
    async reset(all = true) {
      if (all) {
        await this.resetPoolsState()
        await this.httpGetProvidedPools()
      }
      this.resetForm()
    },
    async chooseToken(response) {
      await this.reset(false)

      this.setPoolsTokenData({
        dst: response.direction,
        data: {symbol: response.token.symbol}
      })
      if (this.isTokensHasName) {
        await this.httpGetPoolIdByTokenPair().catch(() => {
        })
      }
      if (this.isTokenHasId && !this.isTokensEqual) {
        await this.httpGetTokenBalancesById()
        await this.httpGetPoolTokensTotal()
      }
      this.calculateEstimatedAmount()
      this.checkIfValid()
    },
    estimateAmount(response) {
      this.form.estimated = this.getInverseDirection(response.direction)
      this.setPoolsTokenData({
        dst: response.direction,
        data: {amount: new BigNumber(response.amount)}
      })
      this.calculateEstimatedAmount()
      this.checkIfValid()
    },
    calculateEstimatedAmount() {
      this.calculateFormData()
      if (!this.isTokensHasName) {
        if (this.form.estimated) {
          this.setPoolsTokenData({
            dst: this.form.estimated,
            data: {amount: new BigNumber(0)}
          })
        }
      } else if (this.isTokensEqual) {
        this.reset(false)
        return
      } else if (!this.isTokenHasId) {
        return
      } else if (this.isTokenHasId && this.form.estimated) {
        const estAmount = this.$store.state.pools[this.getInverseDirection(this.form.estimated)].amount
            .multipliedBy(
                (this.form.estimated === 'from' ? this.form.ratioAB : this.form.ratioBA))
        this.setPoolsTokenData({
          dst: this.form.estimated,
          data: {amount: new BigNumber(estAmount)}
        })
      }

      this.checkIfValid()
    },
    calculateFormData() {
      const pool = this.$store.state.pools
      this.resetForm()
      if (!this.isTokenHasId) {
        this.form.poolTokensReceived = Math.sqrt(
            (+pool.from.amount || 0) * (+pool.to.amount || 0)).toString();
        this.form.shareOfPool = '100';
        this.form.shareOfPoolTooltip = new BigNumber(100).valueOf();
        this.form.ratioAB = mathRound(+(pool.from.amount / pool.to.amount) || 0).toString()
        this.form.ratioBA = mathRound(+(pool.to.amount / pool.from.amount) || 0).toString()
      } else {
        let poolTokensReceivedTmp = +(+pool.total_pool_tokens
            * Math.min((pool.from.amount / pool.from.balance), (pool.to.amount / pool.to.balance))) || 0
        if (poolTokensReceivedTmp === Infinity) {
          poolTokensReceivedTmp = 0
        }

        this.form.poolTokensReceived = mathRound(poolTokensReceivedTmp).toString()
        this.form.poolTokensReceivedTooltip = poolTokensReceivedTmp
        this.form.shareOfPoolTooltip = +(poolTokensReceivedTmp / (+pool.total_pool_tokens + poolTokensReceivedTmp)) * 100 || 0
        this.form.shareOfPool = mathRound(this.form.shareOfPoolTooltip, 2).toString()

        this.form.ratioAB = mathRound(+(pool.from.balance / pool.to.balance) || 0).toString()
        this.form.ratioABTooltip = new BigNumber(pool.from.balance).dividedBy(pool.to.balance).valueOf()
        this.form.ratioBA = mathRound(+(pool.to.balance / pool.from.balance) || 0).toString()
        this.form.ratioBATooltip = new BigNumber(pool.to.balance).dividedBy(pool.from.balance).valueOf()
        this.form.currentPoolSize = `${mathRound(pool.from.balance, 4)}/${mathRound(pool.to.balance, 4)}`
        this.form.currentPoolSizeTooltip = `${pool.from.balance}/${pool.to.balance}`

        if (this.existsInProviderPools) {
          this.providedPool = this.getProvidedPool
          this.form.shareOfPoolTooltip = +((poolTokensReceivedTmp + +this.providedPool.pool_tokens_owned)
              / (+pool.total_pool_tokens + poolTokensReceivedTmp)) * 100 || 0
          this.form.shareOfPool = mathRound(this.form.shareOfPoolTooltip, 2).toString()
          this.form.currentShareOfPool = mathRound(+(this.providedPool.pool_tokens_owned / pool.total_pool_tokens) * 100 || 0, 2).toString()
          this.form.currentShareOfPoolTooltip =
              new BigNumber(this.providedPool.pool_tokens_owned).dividedBy(pool.total_pool_tokens).multipliedBy(100).valueOf()
          this.form.poolTokensOwned = mathRound(+(this.providedPool.pool_tokens_owned) || 0).toString()
          this.form.poolTokensOwnedTooltip = new BigNumber(this.providedPool.pool_tokens_owned).valueOf()
        }
      }

    },
    checkIfValid() {
      if (!this.isTokensEqual && this.isTokensHasName && this.isTokensHasAmount) {
        this.$emit('valid', true);
      } else {
        this.$emit('valid', false);
      }
    },
    resetForm() {
      this.form = {
        shareOfPool: null,
        shareOfPoolTooltip: null,
        poolTokensReceived: null,
        poolTokensReceivedTooltip: null,
        ratioAB: null,
        ratioABTooltip: null,
        ratioBA: null,
        ratioBATooltip: null,
        currentPoolSize: null,
        currentPoolSizeTooltip: null,
        currentShareOfPool: null,
        currentShareOfPoolTooltip: null,
        poolTokensOwned: null,
        poolTokensOwnedTooltip: null,
        estimated: this.form.estimated
      }
      this.providedPool = null
    },
    getInverseDirection(direction) {
      return direction === 'from' ? 'to' : 'from'
    },
  },
  computed: {
    ...mapGetters([
      'isTokensHasAmount',
      'isTokensHasName',
      'isTokensEqual',
      'isTokenHasId',
      'existsInProviderPools',
      'getProvidedPool',
      'checkBaseToken'
    ]),
  }
}
</script>

<style lang="scss">

</style>
